import React, { Component } from "react"
import Layout from "../../components/layout"
import Clutch from "../../components/clutch"

import Dipro_logo from "../../images/diamonds-pro/dipro_logo.svg"
import Traffic from "../../images/igadget/traffic.svg"
import Revenue from "../../images/igadget/revenue.svg"
import Transaction from "../../images/igadget/transaction.svg"
import Gadget_client1 from "../../images/diamonds-pro/dipro_img-11.png"
import dipro_img2 from "../../images/diamonds-pro/dipro_img-2.png"
import dipro_img3 from "../../images/diamonds-pro/dipro_img-3.png"
import Dipro_process from "../../images/diamonds-pro/dipro-process.png"
import Dipro_jewl1 from "../../images/diamonds-pro/dipro-jewl1.png"
import Dipro_jewl2 from "../../images/diamonds-pro/dipro-jewl2.png"
import Dipro_profile from "../../images/diamonds-pro/dipro-rat-profile.png"
import Dipro_star from "../../images/diamonds-pro/Star.png"
import Comma from "../../images/diamonds-pro/comma.png"




import Scroll_top_img from "../../images/scroll-arrow.png"
import Helmet from "react-helmet"
import "../../styles/includes/_google-shopping-ansel-and-ivy.less";
import { Link } from "gatsby";

import Calltoaction from "../../components/Call-to-action"

export default class Container extends Component {
  constructor(props) {
    super(props);
    this.state = {
      url: ''
    }
  }

  componentDidMount() {
    this.setState({
      url: document.location.href
    });
  }
  render() {
    return (
      <Layout>
        <Helmet>
          <title>Mobile Accessory Store Case Study | Google Shopping Raises Revenue</title>
          <meta name="description"
            content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
          <meta http-equiv="X-UA-Compatible" content="IE=edge" />
          <link rel="canonical" href={this.state.url} />

          <meta property="og:site_name" content="CueForGood" />
          <meta property="fb:app_id" content="289086684439915" />
          <meta property="og:url" content={this.state.url} />
          <meta property="og:title"
            content="Mobile Accessory Store Case Study | Google Shopping Raises Revenue" />
          <meta property="og:description"
            content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
          <meta property="og:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
          
          <meta name="twitter:description"
            content="iGadget is a mobile & laptops repairs plus accessory store based in New Zealand. Read our Case Study on how iGadget pulled in 23.59% of total orders via Google Shopping." />
          <meta name="twitter:title"
            content="Mobile Accessory Store Case Study | Google Shopping Raises Revenue" />
          <meta name="twitter:site" content="@CueForGood" />
          <meta name="twitter:image" content="https://www.cueforgood.com/images/merging-blog.jpg" />
        </Helmet>
        <div className="cb_expertiseInner cb_igadget cb_diamondsPro_casewrap">
          <div className="case_study_page perennialcycle organic-perennial ansel-ivy-googleshopping">

            <div className="case-wrap  ansel-casewrap ">
              <div className="ww-sec-one ">
                <div className="container">
                  <h3 className="cb_igadget-heading">Case Study</h3>
                  <h1><img style={{ maxWidth: 200 }} src={Dipro_logo} alt="Diamonds Pro logo" /></h1>
                  <p className="ansel_p">Increasing Organic Users by <strong>118.84%</strong> for The Diamond Pro through Search Engine Optimization (SEO) </p>

                  <div className="revenue-perennial">
                    <div className="revenue-inn">
                      <div className="revenue-single circle1">
                        <div className="revenue-single-outer">
                          <div className="progress dipro1">

                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right hiddenprogress">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value"> 118.84%</div>
                          </div>
                        </div>
                        <p>Increase in <br />organic users</p>
                      </div>

                      <div className="revenue-single circle1">
                        <div className="revenue-single-outer">
                          <div className="progress dipro2">

                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right hiddenprogress">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value"> 114.14%</div>
                          </div>
                        </div>
                        <p>Increase in<br />organic sessions</p>
                      </div>
                      <div className="revenue-single circle1">
                        <div className="revenue-single-outer">
                          <div className="progress dipro3">

                            <span className="progress-left">
                              <span className="progress-bar"></span>
                            </span>
                            <span className="progress-right hiddenprogress">
                              <span className="progress-bar"></span>
                            </span>
                            <div className="progress-value"> 169.21%</div>
                          </div>
                        </div>
                        <p>Increase in <br />goal completions </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="about-client-Ansel about-client-igadget about-client-dipro">
              <div className="container">
                <p className="note"><strong>Note:</strong> *The figures above are based on a comparison of data from 1st Jan 2019 - 31st Aug 2019 to 1st Jan 2021 - 31st Aug 2021</p>
                <div className="about-inn">
                  <div className="about-left about-inn-ele">
                    <h2 className="heading_main">About the client</h2>
                    <p className="dipro-paragraph">Founded in 2009 by Ira Weissman, The Diamond Pro is an affiliate marketing company from Switzerland that aims to educate customers on making an informed decision before purchasing diamond jewelry, especially engagement and wedding rings.</p>
                    <p className="dipro-paragraph">The company has a website where interested consumers can find detailed information related to everything diamond, from diamond shape, cut & certifications to detailed guides on how to make the best purchase when shopping for engagement rings of a desired budget. They also provide unbiased reviews of diamond retailers - online and offline, which helps customers pick the best suited retailer for their purchase. Their website allows visitors to contact them and get their personal queries addressed.</p>
                    <p className="dipro-paragraph">The client wanted to increase organic traffic and affiliate clicks on their website. In order to find opportunities, they hired CueForGood to carry out an SEO audit of the website. </p>
                  </div>
                  <div className="about-right about-inn-ele">
                    <img className="about-client-igadget-img1" src={Gadget_client1} alt="About the Client  " />
                    {/* <div className="cb_good_section"><p className="contactus-area"><strong>Mike Fried CEO </strong> <br />CEO - The Diamonds Pro</p></div> */}
                  </div>
                  <div className="about-right about-inn-ele">
                    <img className="about-client-igadget-img1" src={dipro_img2} alt="Diamond Ring" />
                  </div>
                  <div className="about-left about-inn-ele">
                    <h2 className="heading_main">Analysis</h2>
                    <p className="dipro-paragraph">The Diamond Pro was a leader in its space even before CueForGood started working on their website. Initially tasked with doing a comprehensive SEO audit of The Diamond Pro website, CueForGood discovered that the website was mostly in a good state, except for some technical issues.</p>

                    <p className="dipro-paragraph"> The analysis revealed that their website needed improvement in indexing of unwanted segments. There were broken links throughout the website that needed to be fixed. There were also redirect chains, ‘hreflang’ tag issues, and pagination links that ended in 301. CueForGood found out that the client had lost review snippets in SERPs as well. </p>

                    <p className="dipro-paragraph"> The client had specifically asked us to review some of their website content pages to see if they could be improved further. As a trial, CueForGood was given five existing articles from their website to suggest recommendations. Our analysis revealed that there were some content gaps that we could fill in, and with the help of their amazing copywriting team, CueForGood managed to further optimize articles that were already performing well.</p>
                  </div>
                  <div className="about-left about-inn-ele dp-pad-bl">
                    <p className="dipro-paragraph">We added new questions derived from relevant “People Also Ask” queries from SERPs, provided sub-sections that were not covered earlier, and added guidelines on how to answer the queries to increase visibility for their featured snippets.
                      Our tasks later expanded to include navigational structure improvements, design, and technical help for the website. This involved developing custom plugins, integrating other plugins, website maintenance, etc. </p>

                    <p className="dipro-paragraph">   CueForGood also actively worked with The Diamond Pro team on their GA/GTM events to help track various affiliate events on the site accurately.
                    </p>
                  </div>
                  <div className="about-right about-inn-ele dip-inn-last">
                    <img className="about-client-igadget-img1" src={dipro_img3} alt="About the Client  " />
                  </div>
                </div>
              </div>
            </div>


            <div className="about-client-dipro contact_us">
              <div className="container">

                <div className="cb_good_section">
                  <div className="contactus-area">
                    <p>Don't let missed opportunities hold your business back. Get in touch with CueForGood now!</p>
                    <div className="right-cont"><a className="contactus-btn focus-visible" href="/contact/" id="first-focus-element" >Contact us</a></div>
                  </div>
                </div>
              </div>
            </div>


<div className="Challenges  anselivy-challenges dipro-challanges">
              <div className="container">
                <h2 className="heading_main">Our Process</h2>
                <div className="out-Challenges">
                  <div className="dipro-challanges-inner">
                    <div className="inn-Challenges">
                      <h2 className="heading_count">1</h2>
                      <p>CueForGood conducted a comprehensive SEO audit in September 2019.</p>
                      <img className="about-client-igadget-img1" src={Dipro_jewl1} alt="About the Client  " />
                    </div>
                    <div className="inn-Challenges inn-Challenges-2res">
                    <h2 className="heading_count">2</h2>
                    <p className="dipro-paragraph">CueForGood worked on providing *content briefs for optimizing their existing articles. The content briefs ranged from 10 to 20 briefs a month. Initially, the content briefs were created for existing articles, but after working on 90% of the articles on the website (close to 100 articles), our team continued to provide content briefs for new topics that were not yet covered.</p>
                    <p className="dipro-paragraph">*Our content brief includes subtopics, guidelines on what to cover in subtopics, keywords to include in the content, on-page optimization suggestions, internal linking suggestions, anchor text suggestions, and image alt text suggestions.</p>
                    <p className="dipro-paragraph">As an SOP, at CueForGood, we work on an iterative keyword research program when initiating a new SEO project. The keyword research is then clustered into smaller hubs and spoken articles to increase the website’s topical relevance for targeted topics.</p>
                    </div>
                    <div className="inn-Challenges">
                      <h2 className="heading_count">3</h2>
                      <p>CueForGood also worked on a series of technical issues on The Diamond Pro website. These included the following:</p>
                      <ul>
                        <li>Historical URLs going through multiple 301 redirects (redirect chains)</li>
                        <li>Internal links that led to 404</li>
                        <li>Internal Links that were 301 Redirected</li>
                        <li>404 URLs found in Google Analytics</li>
                        <li>'hreflang' tag issues in GSC</li>
                        <li>Pagination links ending in 301</li>
                        <li>Removed unneeded URLs from the sitemap</li>
                      </ul>
                    </div>
                  </div>

                  <div className="inn-Challenges dipro-challanges-inner inn-Challenges-2desk ">
                    <h2 className="heading_count">2</h2>
                    <p className="dipro-paragraph">CueForGood worked on providing *content briefs for optimizing their existing articles. The content briefs ranged from 10 to 20 briefs a month. Initially, the content briefs were created for existing articles, but after working on 90% of the articles on the website (close to 100 articles), our team continued to provide content briefs for new topics that were not yet covered.</p>
                    <p className="dipro-paragraph">*Our content brief includes subtopics, guidelines on what to cover in subtopics, keywords to include in the content, on-page optimization suggestions, internal linking suggestions, anchor text suggestions, and image alt text suggestions.</p>
                    <p className="dipro-paragraph">As an SOP, at CueForGood, we work on an iterative keyword research program when initiating a new SEO project. The keyword research is then clustered into smaller hubs and spoken articles to increase the website’s topical relevance for targeted topics.</p>
                  </div>
                </div>
              </div>
            </div>


            <div className="dpro-result">
              <div className="container">
                <h2>Results</h2>
                <p>The development team at CueForGood ensured that The Diamond Pro's website health remained excellent by consistently working on website improvements and maintenance.</p>
                <p>The SEO team improved the site’s organic traffic by over 100% and also raise the percentage of affiliate conversions by almost 170% by addressing crucial content gaps.</p>
                <p>With our experience and efforts we successfully improved the organic search performance of the website. </p>
                <p>We are fortunate and proud to be associated with a brand like The Diamond Pro and add value to their business.</p>
              </div>
            </div>


            <div className="dipro_review comment">
              <div className="container">
                <div className="cb_good_section">
                <img className="dipro_profile-abs comma" src={Comma} alt="comma" />
                <img className="dipro_profile-abs comma comma-r" src={Comma} alt="comma" />
                  <div className="contactus-area">
                    <p role="heading" aria-level="2">“The company's traffic skyrocketed by 70%, and the click-through rate (CTR) increased by 80%, producing a large positive impact on our business. Customers can expect outstanding project management and fast response times from CueForGood.”</p>
                    <div className="des-tn">
                      <p><strong>Mike Fried</strong></p>
                      <p>CEO - The Diamond Pro</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div className="dipro_review star">
              <div className="container rev-profile">
               <img className="dipro_profile-abs" src={Dipro_profile} alt="Star" />
                <div className="cb_good_section">
                  <div className="contactus-area">
                    <div className="dip-rev-st ">
                      <h2>5.0</h2>
                      <div className="dipro-review-star" >
                        <img className="" src={Dipro_star} alt="Star" />
                        <img className="" src={Dipro_star} alt="Star" />
                        <img className="" src={Dipro_star} alt="Star" />
                        <img className="" src={Dipro_star} alt="Star" />
                        <img className="" src={Dipro_star} alt="Star" />

                      </div>
                      <h3>Overall Score</h3>
                    </div>

                    <div className="dip-rev-st "><p>Scheduling  |  Cost  |  Quality  |  Net Promoter Score(NPS)</p></div>
                  </div>
                </div>
              </div>
            </div>


            <div className="about-client-dipro contact_us contact_us-1">
              <div className="container">
                <img className="dipro-img-jewl1 dipro_jewl" src={Dipro_process} alt="About the Client  " />
                <img className="dipro-img-jewl2 dipro_jewl" src={Dipro_jewl2} alt="About the Client  " />
                <div className="cb_good_section">
                  <div className="contactus-area">
                    <p>Drive more relevant traffic from search engines to your site and increase conversions</p>
                    <div className="right-cont"><a className="contactus-btn focus-visible" href="/contact/">Contact us</a></div>
                    <div className="dipro-orcl">
                      <p>OR</p>
                    </div>
                    <p className="dipro-pNo-pad">Write to us at <a href="mailto:letstalk@cueforgood.com" className="focus-visible">letstalk@cueforgood.com</a></p>
                  </div>
                </div>
              </div>
            </div>


            <div id="scroll-to" className="case-scroll">
              <a href="#" title="Scroll To Top" className="focus-visible"><img src={Scroll_top_img} alt="Scroll To Top" /></a>
            </div>
          </div>
        </div>

        <Clutch />

      </Layout>
    )
  }
}